<template>
  <v-app >
    <cabecera/>
    <v-main style="background: #eef5f9"
    >
      <v-container    >
        <v-row class="text-center">
          <v-col cols="12">
            <section id="eventos">
              <v-row  class="text-center">
                <v-col cols="12" sm="12" md="12" lg="12">
                  <h1 class="display-1 font-weight-bold">Mis Entrada</h1>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-btn class="primary" @click="imprimir">
                    <v-icon left>
                      fas fa-print
                    </v-icon>Imprimir</v-btn>
                </v-col>
              </v-row>

              <v-row justify="center" >

                <v-col cols="12" lg="8" sm="3"  >
                  <v-card  id="laentrada" elevation="10">
                    <v-card-title   ><v-img max-width="100" src="@/assets/dtyf.png"  /></v-card-title>
                    <v-card-text >
                    <v-row>
                      <v-col cols="12" lg="3" md="3" sm="6">
                        <vue-qrcode :value="ticket.codigoUnico" />
                      </v-col>
                      <v-col cols="12" lg="9" md="9" sm="6">
                        <v-row>
                        <v-col cols="12" lg="12" md="12" sm="12">
                         <p style="font-weight: bolder; font-size: 25pt; text-align: left;">Evento</p>
                        </v-col>
                          <v-col cols="12" lg="12" md="12" sm="12">
                            <p style="font-size: 18pt;text-align: left;">{{ticket.eventoEntrada.eventoEntradaZonaEvento.zonaEventoEvento.evento}}</p>
                          </v-col>
                          <v-col cols="12" lg="6" md="6" sm="6">
                            <v-row>
                              <v-col cols="12" lg="12" md="12" sm="12">
                                <p style="font-weight: bolder; font-size: 25pt; text-align: left;">Entrada</p>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" lg="12" md="12" sm="12">
                               <p style="font-size: 18pt;text-align: left;">{{ticket.eventoEntrada.eventoEntrada}}</p>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="12" lg="6" md="6" sm="6">
                            <v-row>
                              <v-col cols="12" lg="12" md="12" sm="12">
                                <p style="font-weight: bolder; font-size: 25pt; text-align: left;">Zona</p>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" lg="12" md="12" sm="12">
                               <p style="font-size: 18pt;text-align: left;"> {{ticket.eventoEntrada.eventoEntradaZonaEvento.zonaEvento}}</p>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="12" lg="12" md="12" sm="12">
                            <p style="font-weight: bolder; font-size: 25pt; text-align: left;">Nombre</p>
                          </v-col>
                          <v-col cols="12" lg="12" md="12" sm="12">
                            <p style="font-size: 18pt;text-align: left;">{{ticket.persona.personaNombre}} {{ticket.persona.personaApellidoPaterno}} {{ticket.persona.personaApellidoMaterno}}</p>
                          </v-col>
                          <v-col cols="12" lg="12" md="12" sm="12">
                            <p style="font-weight: bolder; font-size: 25pt; text-align: left;">DNI</p>
                          </v-col>
                          <v-col cols="12" lg="12" md="12" sm="12">
                            <p style="font-size: 18pt;text-align: left;">{{ticket.persona.personaDni}}</p>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </section>


          </v-col>
        </v-row>
        <img :src="output">
      </v-container></v-main>
    <piepagina/>
    <v-overlay :value="overlay">
      <v-img
          src="@/assets/loading.gif"

      />
    </v-overlay>
  </v-app>
</template>
<script>
import cabecera from './general/cabecera'
import piepagina from './general/piepagina';
import {mapGetters} from "vuex";
import VueQrcode from 'vue-qrcode'
import Vue2Img from "vue-2-img";
import "vue-2-img/dist/vue-2-img.css";

export default {
  name: 'entrada',
  components: {
    cabecera,
    piepagina,
    VueQrcode
  },
  data ()  {
    return {
      overlay:false,
      output: null,
      encodedPdf: "",


    }
  },
  methods:{
    async  imprimir(){
      var vm = this;
      let config = {
        target: "body",
        pageTarget: "#laentrada",
        captureHiddenClass: "vti__hidden",
        captureShowClass: "vti__show",
        captureActiveClass: "vti__active",
        title: "Entrada",
        author: "html-image-capture-service",
        maxItems: 50,
        fileNameSuffix: "entrada",
        pageWrapper: ".row",
        padding: 4,
        devStyle: false,
        pageHeight: null, // 612 for letter
        pageWidth: null, // 792 for letter
        pageUnits: "pt",
        returnAction: "download",
      };
      vm.encodedPdf = await Vue2Img().pdf(config);
      vm.downloadPDf(vm.encodedPdf);
    }
  },
  computed: {
  // map `this.user` to `this.$store.getters.user`
...mapGetters({
    usuario:"usuario",
    ticket: "ticket"
  }),
    downloadPDf() {
      return (source) => {
        const downloadLink = document.createElement("a");
        downloadLink.href = source;
        downloadLink.download = "entrada.pdf";
        downloadLink.click();
      };
    },
}
}
</script>